<template>
  <div>
    <el-dialog
      title="编辑MB账号"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="500px"
      custom-class="dialog"
      @close="resetForm"
    >
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        style="padding: 0"
      >
        <el-form-item label="MB账号" prop="account">
          <el-input
            v-model="form.account"
            class="formWidth"
            maxlength="16"
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="pwd">
          <el-input
            v-model="form.pwd"
            class="formWidth"
            maxlength="16"
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save" :loading="saveLoading"
          >{{ $t('button.preservation') }}</el-button
        >
        <el-button @click="resetForm">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    let checkName = (rule, value, callback) => {
      if (/^[\da-z]{6,16}$/.test(value)) {
        callback();
      } else {
        callback(new Error("账号必须由字母、数字或字母数字组合,长度6-16位"));
      }
    };
    let checkCode = (rule, value, callback) => {
      if (/^[\da-z]{6,16}$/.test(value)) {
        callback();
      } else {
        callback(new Error("密码必须由字母、数字或字母数字组合,长度6-16位"));
      }
    };
    return {
      msAccountId: "",
      dialogVisible: false,
      form: {
        account: "",
        pwd: "",
      },
      rules: {
        account: [
          {
            required: true,
            message: "账号必填",
            trigger: "blur",
          },
          { validator: checkName, trigger: "blur" },
        ],
        pwd: [
          {
            required: true,
            message: "密码必填",
            trigger: "blur",
          },
          { validator: checkCode, trigger: "blur" },
        ],
      },
      saveLoading: false,
    };
  },
  methods: {
    // 打开窗口
    open() {
      this.dialogVisible = true;
      for (let i of Object.keys(this.form)) {
        this.form[i] = "";
      }
    },
    // 保存
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          this.$axios
            .post("/acb/2.0/bacb/msAccount/modify", {
              data: {
                parkId: this.$route.query.parkId,
                msAccountId: this.msAccountId,
                ...this.form,
              },
            })
            .then((res) => {
              this.saveLoading = false;
              if (res.state == 0) {
                // if (res.value) {
                this.$emit("searchData");
                this.dialogVisible = false;
                // }
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            })
            .catch((error) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },

    // 详情
    getDetail({ msAccountId }) {
      this.msAccountId = msAccountId;
      this.$axios
        .get("/acb/2.0/bacb/msAccount/getById/" + msAccountId, {
          data: {},
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.form.account = res.value.account;
              this.form.pwd = res.value.pwd;
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 取消
    resetForm() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
  },
  created() {},
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>

