<template>
  <div>
    <el-dialog :title="addEdit=='edit' ? '编辑设备' : '添加设备'" :visible.sync="dialogVisible" width="60%"
      :close-on-click-modal='false' custom-class='dialog' @close='resetForm'>
      <el-form label-position="right" :inline="true" label-width="100px" :model="form"
        :rules="rules" ref="form">
        <el-form-item :label="$t('searchModule.Equipment_name')" prop="equipmentName">
          <el-input :maxlength='50' v-model.trim="form.equipmentName" placeholder="请输入设备名称">
          </el-input>
        </el-form-item>
        <el-form-item label="设备编码" prop="equipmentCode">
          <el-input :maxlength='30' v-model.trim="form.equipmentCode" placeholder="请输入设备编码">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.Equipment_type')" prop="equipmentType">
          <el-select v-model="form.equipmentType" placeholder="请选择设备类型">
            <el-option label="智能控制板" value="13"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save" :loading="saveLoading">{{ $t('button.preservation') }}</el-button>
        <el-button @click="resetForm">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        saveLoading: false,
        equipmentId: '',
        form: {
          equipmentName: '',
          equipmentCode: '',
          equipmentType: '13'
        },
        rules: {
          equipmentName: [{
              required: true,
              message: '设备名称必填',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 20,
              message: '最少1位，最多20位',
              trigger: 'blur'
            }
          ],
          equipmentCode: [{
              required: true,
              message: '设备编码必填',
              trigger: 'blur'
            },
            {
              pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{2,50}$/,
              message: '数字+字母组合；最少2位，最大50位'
            }
          ],
          equipmentType: [{
            required: true,
            message: '设备类型必填',
            trigger: 'blur'
          }]
        },
        addEdit: '',
        parkId: ''
      }
    },
    methods: {
      // 打开窗口
      open(v, id, data) {
        this.dialogVisible = true;
        for (let i in this.form) {
          this.form[i] = '';
        }
        this.addEdit = v
        this.parkId = id
        if (v == 'edit') {
          this.equipmentId = data.equipmentId
          this.form.equipmentCode = data.equipmentCode
          this.form.equipmentName = data.equipmentName
          this.form.equipmentType = String(data.equipmentType)
        }
      },
      // 保存
      save() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.saveLoading = true;
            let url = this.addEdit == 'edit' ? '/acb/2.0/bacb/equipment/update' :
              '/acb/2.0/bacb/equipment/add'
            let data = this.addEdit == 'edit' ? {
              equipmentId: this.equipmentId,
              parkId: this.parkId,
              ...this.form
            } : {
              parkId: this.parkId,
              ...this.form
            }
            this.$axios.post(url, {
              data: data
            }).then(res => {
              this.saveLoading = false;
              if (res.state == 0) {
                this.dialogVisible = false;
                this.$emit('searchData');
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                })
              }
            }).catch(() => {
              this.saveLoading = false;
            })
          }
        })
      },
      // 取消
      resetForm() {
        this.dialogVisible = false;
        this.$refs.form.resetFields();
      }
    }
  }

</script>
<style>
  .formWidth {
    width: 221.5px;
  }

</style>
