<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" ref='form' :model="formInline"
          class="demo-form-inline">
          <el-form-item :label="$t('searchModule.Equipment_name')">
            <el-input v-model="formInline.equipmentName" placeholder="请输入设备名称" clearable>
            </el-input>
          </el-form-item>
          <el-form-item label="">

            <el-button type="primary" icon="el-icon-search" v-if='authority.button.query'
              :loading="loading" @click="page=1;searchData()">
              查询
            </el-button>

            <el-button type="primary" icon="el-icon-plus" v-if='authority.button.add'
              @click='addBerth(uploadData.parkId)'>
              添加
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table header-cell-class-name='header-call-style' v-loading="loading"
          :data="tableData" style="width: 100%;">
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <!--<el-table-column type="selection" width="55"></el-table-column>-->
          <el-table-column :prop="item.prop" :label="item.label" :width="item.width"
            v-for="item in tableCols" :key="item.prop" :formatter="item.formatter" align='center'>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Equipment_status')" align='center'>
            <template slot-scope="scope">
              <div :style="{color: scope.row.equipActualState != '离线' ? 'green':'red'}">{{scope.row.equipActualState}}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align='center' width='100'
            v-if='authority.button.delete || authority.button.edit || authority.button.disable'>
            <template slot-scope="scope">
              <el-dropdown @command='handleCommand($event,scope.row)'>
                <el-button type="text" size="small" style="padding: 0">操作<i class="el-icon-arrow-down" /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command='a' v-if='authority.button.edit'>编辑</el-dropdown-item>
                  <el-dropdown-item command='b' v-if='authority.button.delete'>删除</el-dropdown-item>
                  <!-- <el-dropdown-item command='c' v-if='authority.button.disable'>{{scope.row.equipmentState == '1' ? '禁用' : '启用'}} -->
                  <!-- </el-dropdown-item> -->
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination :current-page="page" :page-size="pageSize"
            layout="total, prev, pager, next, jumper" :total="total"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <edit ref="edit" @searchData='searchData'></edit>
  </div>
</template>

<script>
  import edit from './edit'
  export default {
    name: 'AI',
    props: {
      authority: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    data() {
      return {
        selection: [],
        index: 0,
        total: 0,
        pageSize: 15,
        page: 1,
        loading: false,
        detailFlag: false,
        tableCols: [{
            prop: 'equipmentName',
            label: '设备名称',
            width: ''
          },
          {
            prop: 'equipmentCode',
            label: '设备编码',
            width: ''
          },
          {
            prop: 'equipmentType',
            label: '设备类型',
            width: '',
            formatter: function(row, colunm, cellValue, index) {
              return cellValue == 13 ? '智能控制板' : '其他'
            }
          },
          {
            prop: 'parkChannelName',
            label: '设备关联通道',
            width: ''
          },
          // {
          //   prop: 'equipmentState',
          //   label: '运营状态',
          //   width: '',
          //   formatter: (row, column, cellValue, index) => {
          //     return cellValue == 1 ? "使用" : '停用';
          //   }
          // }
        ],
        tableData: [],
        formInline: {
          equipmentName: ''
        },
        uploadData: {
          'parkId': this.$route.query.parkId
        },
        uploadUrl: '/acb/2.0/bacb/monitorEquipment/batchImport',
        modelUrl: '/acb/2.0/bacb/monitorEquipment/downloadExcelTemplate',
      }
    },
    components: {
      edit
    },
    methods: {
      addBerth(id) {
        this.$refs.edit.open('add', id);
      },

      // 操作菜单项
      handleCommand(cmd, data) {
        if (cmd == 'a') {
          this.$refs.edit.open('edit', data.parkId, data);
        }
        if (cmd == 'b') {
          this.dataDelete(data.equipmentId);
        }
        if (cmd == 'c') {
          this.dataDisabled(data);
        }
      },

      // 分页处理
      handleCurrentChange(val) {
        this.page = val;
        this.searchData();
      },

      selectionChange(selection) {
        this.selection = selection;
      },
      // 搜索
      searchData() {
        this.tableData = [];
        this.loading = true;
        this.$axios.get('/acb/2.0/bacb/equipment/AIList', {
          data: {
            parkId: this.$route.query.parkId,
            pageNum: this.page,
            equipmentType: 13,
            pageSize: this.pageSize,
            ...this.formInline
          }
        }).then(res => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list || [];
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            })
          }
        }).catch(error => {
          this.loading = false;
        })
      },
      // 删除
      dataDelete(id) {
        this.$confirm('将删除此设备,是否继续?', this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let url = '/acb/2.0/bacb/equipment/removeEquipment'
          this.$axios.post(url, {
            data: {
              equipmentId: id
            }
          }).then(res => {
            if (res.state == 0) {
              this.$alert('操作成功！', this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              }).then(() => {
                this.page = 1;
                this.searchData();
              })
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      },
      // 禁用
      dataDisabled(data) {
        this.$confirm(`将${data.equipmentState == '1' ? '禁用' : '启用'}此设备,是否继续?`, this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let url = '/acb/2.0/bacb/equipment/updateEquipmentState'
          this.$axios.post(url, {
            data: {
              equipmentId: data.equipmentId,
              equipmentState: data.equipmentState == '1' ? 2 : 1
            }
          }).then(res => {
            if (res.state == 0) {
              this.$alert('操作成功！', this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              }).then(() => {
                this.page = 1;
                this.searchData();
              })
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine')
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      },
      // 初始化
      init() {
        this.$refs.form && this.$refs.form.resetFields();
        // this.searchData();
      }
    },
    beforeDestroy() {
      sessionStorage.searchData = JSON.stringify(this.formInline);
      sessionStorage.searchFlag = 1;
    },
    created() {},
    mounted() {
      this.searchData()
    },
    computed: {}
  }

</script>
<style scoped lang="stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    background #EFF2F7
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
