var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addEdit == "edit" ? "编辑设备" : "添加设备",
            visible: _vm.dialogVisible,
            width: "60%",
            "close-on-click-modal": false,
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                "label-position": "right",
                inline: true,
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Equipment_name"),
                    prop: "equipmentName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: 50, placeholder: "请输入设备名称" },
                    model: {
                      value: _vm.form.equipmentName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "equipmentName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.equipmentName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设备编码", prop: "equipmentCode" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 30, placeholder: "请输入设备编码" },
                    model: {
                      value: _vm.form.equipmentCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "equipmentCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.equipmentCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Equipment_type"),
                    prop: "equipmentType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择设备类型" },
                      model: {
                        value: _vm.form.equipmentType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "equipmentType", $$v)
                        },
                        expression: "form.equipmentType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "智能控制板", value: "13" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.saveLoading },
                  on: { click: _vm.save },
                },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }