<template>
  <div>
    <el-dialog
      title="添加岗亭组"
      :visible.sync="dialogVisible"
      width="60%"
      :close-on-click-modal="false"
      custom-class="dialog"
      @close="resetForm"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" style="padding: 0">
        <el-form-item label="岗亭组:" prop="sentryBoxName">
          <el-input
            v-model="form.sentryBoxName"
            placeholder="请输入岗亭组名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="关联通道">
          <el-transfer
            target-order="push"
            ref="transfer"
            v-loading="loading"
            :titles="['源列表', '目标列表']"
            filterable
            filter-placeholder="请输入..."
            v-model="channelIds"
            :props="{
              label: 'parkChannelName',
              key: 'parkChannelId',
            }"
            :data="data2"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save" :loading="saveLoading">{{ $t('button.preservation') }}</el-button>
        <el-button @click="resetForm">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      channelIds: [],
      form: {
        sentryBoxName: "",
      },
      rules: {
        sentryBoxName: [{ required: true, message: "请输入岗亭组名称", trigger: "blur" }],
      },
      loading: false,
      saveLoading: false,
      data2: [],
    };
  },
  methods: {
    // 打开窗口内容
    open() {
      // 清空左右面板的搜索关键词
      this.$refs.transfer && this.$refs.transfer.clearQuery("left");
      this.$refs.transfer && this.$refs.transfer.clearQuery("right");
      this.dialogVisible = true;
      this.form.sentryBoxName = "";
      this.channelIds = [];
      // 获取穿梭框通道数据
      this.searchData();
    },
    // 保存
    save() {
      if (this.channelIds.length == 0) {
        this.$alert("请至少选择一个关联通道！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }

      let parms = {};
      parms.sentryBoxName = this.form.sentryBoxName;
      parms.channelIds = this.channelIds.join(",");
      parms.parkId = this.$route.query.parkId;

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          this.$axios
            .post("/acb/2.0/bacb/sentryBox/add", {
              data: parms,
            })
            .then((res) => {
              if (res.state == 0) {
                setTimeout(() => {
                  this.saveLoading = false;
                  this.$emit("searchData");
                  this.dialogVisible = false;
                }, 2000);
              } else {
                this.saveLoading = false;
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            })
            .catch((error) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },

    // 获取穿梭框通道数据
    searchData() {
      this.data2 = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/sentryBox/queryChannelByParkId/" + this.$route.query.parkId, {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.data2 = res.value || [];
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    // 取消
    resetForm() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
  },
  created() {},
};
</script>
<style>
.formWidth {
  width: 221.5px;
}

</style>
