<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="form"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item label="岗亭组">
            <el-input
              v-model="formInline.sentryBoxName"
              placeholder="请输入岗亭组名称"
              clearable
            ></el-input>
          </el-form-item>

          <el-form-item label="">
            <el-button
              type="primary"
              icon="el-icon-search"
              :loading="loading"
              v-if="authority.button.query"
              @click="
                page = 1;
                searchData();
              "
            >
              查询
            </el-button>
            <el-button
              v-if="authority.button.add"
              type="primary"
              icon="el-icon-plus"
              @click="addBerth"
            >
              添加
            </el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="同步状态"
            width=""
            v-if="details.parkSystemType != 0"
            align="center"
          >
            <template slot-scope="scope">
              <span :class="scope.row.syncStatus | colorFilter">{{
                scope.row.syncStatus | stateFilter
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Update_time')" width="" align="center">
            <template slot-scope="scope">
              {{ scope.row.updatedTime }}
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" width="100" v-if="authority.button.edit">
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="authority.button.edit">编辑</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <el-button
            type="primary"
            @click="updateData()"
            :loading="updateLoading"
            :disabled="tableData.length == 0"
            v-if="authority.button.sync"
            >同步本地</el-button
          >
        </div>
        <!--添加岗亭组-->
        <add-berth ref="addBerth" @searchData="searchData"></add-berth>
        <!--编辑岗亭组-->
        <edit-berth ref="editBerth" @searchData="searchData"></edit-berth>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./Add";
import editBerth from "./Edit";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  name: "policeIndex",
  props: {
    authority: {
      type: Object,
      default: function () {
        return {};
      },
    },
    details: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  filters: {
    stateFilter(state) {
      return state == 1 ? "同步成功" : "同步失败";
    },
    colorFilter(state) {
      return state == 1 ? "color_1" : "color_2";
    },
  },
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      updateLoading: false,
      tableCols: [
        {
          prop: "sentryBoxName",
          label: "岗亭组",
          width: "",
        },
        {
          prop: "parkChannelNames",
          label: "关联通道",
          width: "",
        },
      ],
      tableData: [],
      formInline: {
        sentryBoxName: "",
      },
    };
  },
  methods: {
    // 添加
    addBerth() {
      this.$refs.addBerth.open();
    },

    // 操作菜单项
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.$refs.editBerth.getDetail(data);
        this.$refs.editBerth.open();
      }
    },

    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },

    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/sentryBox/list", {
          data: {
            parkId: this.$route.query.parkId,
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list || [];
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 同步本地
    updateData() {
      this.updateLoading = true;
      this.$axios
        .get("/acb/2.0/bacb/sentryBox/sync", {
          data: {
            parkId: this.$route.query.parkId,
          },
        })
        .then((res) => {
          this.updateLoading = false;
          if (res.state == 0) {
            this.$alert(res.value, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            }).then(() => {
              this.page = 1;
              this.searchData();
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch(() => {
          this.updateLoading = false;
          this.page = 1;
          this.searchData();
        });
    },
    // 初始化
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    addBerth,
    editBerth,
  },
  created() {
    // console.log('岗亭组:' + JSON.stringify(this.authority))
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
.color_1
  color #333333
.color_2
  color #D9001B
</style>
