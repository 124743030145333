<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          ref="form"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="收费员姓名">
                <el-input
                  v-model="formInline.msUserName"
                  placeholder="请输入收费员姓名"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="收费员账号">
                <el-input
                  v-model="formInline.account"
                  placeholder="请输入收费员账号"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.role')">
                <el-select v-model="formInline.role" placeholder="请选择">
                  <el-option value="" label="全部">全部</el-option>
                  <el-option
                    v-for="item in details.parkSystemType != 0
                      ? roleOthers
                      : roleAi"
                    :key="item.value"
                    :value="item.value"
                    :label="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                :loading="loading"
                v-if="authority.button.query"
                @click="
                  page = 1;
                  searchData();
                "
              >
                查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button
                v-if="authority.button.add"
                type="primary"
                icon="el-icon-plus"
                @click="addBerth"
              >
                添加
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <!-- <el-table-column type="index" :label="$t('list.index')" width="70" align='center'></el-table-column> -->
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            width="100"
            v-if="authority.button.delete"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="a" v-if="authority.button.delete"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--添加岗亭组-->
        <add-berth
          ref="addBerth"
          :data="tableData"
          @searchData="searchData"
        ></add-berth>
      </div>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import addBerth from "./add";
import { dateFormat } from "../../../../../common/js/public.js";
export default {
  name: "collectorIndex",
  props: {
    authority: {
      type: Object,
      default: function () {
        return {};
      },
    },
    details: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      index: 0,
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      tableCols: [
        {
          prop: "msUserName",
          label: this.$t("list.toll_collector_name"),
          width: "",
        },
        {
          prop: "msUserCode",
          label: "收费员编号",
          width: "",
        },
        {
          prop: "account",
          label: "收费员账号",
          width: "",
        },
        {
          prop: "role",
          label: this.$t("list.role"),
          width: "",
          formatter: (row) => {
            switch (row.role) {
              case 0:
                return "岗亭收费员";
              case 1:
                return "岗亭运维员";
              case 2:
                return "PDA收费员";
              default:
            }
          },
        },
      ],
      roleAi: [
        {
          value: 2,
          name: "PDA收费员",
        },
      ],
      roleOthers: [
        {
          value: 0,
          name: "岗亭收费员",
        },
        {
          value: 1,
          name: "岗亭运维员",
        },
      ],
      tableData: [],
      formInline: {
        msUserName: "",
        account: "",
        role: "",
      },
    };
  },
  methods: {
    // 添加
    addBerth() {
      this.$refs.addBerth.open();
    },

    // 删除
    deleteData({ userParkId }) {
      this.$confirm("确定要删除该收费员跟车场的关联？", this.$t('pop_up.Tips'), {
        confirmButtonText: this.$t('pop_up.Determine'),
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .get("/acb/2.0/bacb/msUserPark/delete/" + userParkId, {})
            .then((res) => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                this.page = 1;
                this.searchData();
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },

    // 操作菜单项
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.deleteData(data);
      }
    },

    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },

    // 搜索
    searchData() {
      this.tableData = [];
      this.loading = true;
      this.$axios
        .get("/acb/2.0/bacb/msUserPark/list", {
          data: {
            parkId: this.$route.query.parkId,
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.formInline,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.tableData = res.value.list || [];
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    // 初始化
    init() {
      this.$refs.form && this.$refs.form.resetFields();
      this.searchData();
    },
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  components: {
    addBerth,
  },
  created() {
    // console.log('收费员管理:' + JSON.stringify(this.authority))
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  background: #FFFFFF;
  overflow: hidden;

  .searchWrapper {
    overflow: hidden;
    padding: 22px 22px 0;
    // margin-bottom: 20px;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}
</style>
